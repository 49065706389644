import type * as Types from '@/types/graphql';

import type { ComputedRef, Ref } from 'vue';
import { useMutation, type UseMutationOptions } from 'vue-query';
import { appFetcher } from '@/api/gatewayClient';
export type ThemePageMetaCreateOrUpdateMutationVariables = Types.Exact<{
  input: Types.CreateThemePageMetaInput;
  themePageID: Types.Scalars['ID'];
}>;

export type ThemePageMetaCreateOrUpdateMutationResponse = {
  themePageMetaCreateOrUpdate?: Types.Maybe<
    Pick<Types.ThemePageMeta, 'createdAt' | 'id' | 'key' | 'updatedAt' | 'value'>
  >;
};

export const ThemePageMetaCreateOrUpdateDocument = `
    mutation themePageMetaCreateOrUpdate($input: CreateThemePageMetaInput!, $themePageID: ID!) {
  themePageMetaCreateOrUpdate(input: $input, themePageID: $themePageID) {
    createdAt
    id
    key
    updatedAt
    value
  }
}
    `;
export const useThemePageMetaCreateOrUpdateMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    ThemePageMetaCreateOrUpdateMutationResponse,
    TError,
    ThemePageMetaCreateOrUpdateMutationVariables,
    TContext
  >,
) =>
  useMutation<
    ThemePageMetaCreateOrUpdateMutationResponse,
    TError,
    ThemePageMetaCreateOrUpdateMutationVariables,
    TContext
  >(
    ['themePageMetaCreateOrUpdate'],
    (
      variables:
        | ThemePageMetaCreateOrUpdateMutationVariables
        | ComputedRef<ThemePageMetaCreateOrUpdateMutationVariables>
        | Ref<ThemePageMetaCreateOrUpdateMutationVariables>,
    ) =>
      appFetcher<ThemePageMetaCreateOrUpdateMutationResponse, ThemePageMetaCreateOrUpdateMutationVariables>(
        ThemePageMetaCreateOrUpdateDocument,
        variables,
      )(),
    options,
  );
useThemePageMetaCreateOrUpdateMutation.getKey = () => ['themePageMetaCreateOrUpdate'];
