import type * as Types from '@/types/graphql';

import type { ComputedRef, Ref } from 'vue';
import { useMutation, type UseMutationOptions } from 'vue-query';
import { appFetcher } from '@/api/gatewayClient';
export type ThemePageRemoveThemeSectionsMutationVariables = Types.Exact<{
  themeSectionIDs: Array<Types.Scalars['ID']>;
  id: Types.Scalars['ID'];
}>;

export type ThemePageRemoveThemeSectionsMutationResponse = Pick<Types.Mutation, 'themePageRemoveThemeSections'>;

export const ThemePageRemoveThemeSectionsDocument = `
    mutation themePageRemoveThemeSections($themeSectionIDs: [ID!]!, $id: ID!) {
  themePageRemoveThemeSections(themeSectionIDs: $themeSectionIDs, id: $id)
}
    `;
export const useThemePageRemoveThemeSectionsMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    ThemePageRemoveThemeSectionsMutationResponse,
    TError,
    ThemePageRemoveThemeSectionsMutationVariables,
    TContext
  >,
) =>
  useMutation<
    ThemePageRemoveThemeSectionsMutationResponse,
    TError,
    ThemePageRemoveThemeSectionsMutationVariables,
    TContext
  >(
    ['themePageRemoveThemeSections'],
    (
      variables:
        | ThemePageRemoveThemeSectionsMutationVariables
        | ComputedRef<ThemePageRemoveThemeSectionsMutationVariables>
        | Ref<ThemePageRemoveThemeSectionsMutationVariables>,
    ) =>
      appFetcher<ThemePageRemoveThemeSectionsMutationResponse, ThemePageRemoveThemeSectionsMutationVariables>(
        ThemePageRemoveThemeSectionsDocument,
        variables,
      )(),
    options,
  );
useThemePageRemoveThemeSectionsMutation.getKey = () => ['themePageRemoveThemeSections'];
