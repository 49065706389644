import { defineStore } from 'pinia';

export const useLockSavedStore = defineStore('lock-saved', {
  state: () => ({
    currentTimePageSaved: 0,
    lastTimePageSaved: 0,
  }),
  getters: {
    getCurrentTimePageSaved(state) {
      return state.currentTimePageSaved;
    },
    getLastTimePageSaved(state) {
      return state.lastTimePageSaved;
    },
  },
  actions: {
    clear() {
      this.$reset();
    },
    setCurrentTimePageSaved(value: number) {
      this.currentTimePageSaved = value;
    },
    setLastTimePageSaved(value: number) {
      this.lastTimePageSaved = value;
    },
  },
});
