import type { ShopSelectFragment } from '@/api/app/fragments/shop.generated';
import { PlanKey } from '@/modules/editor/modules/settings/constants/plan';
import type { TSurveysShown } from '@/modules/survey/types';
import dayjs from 'dayjs';
import { defineStore } from 'pinia';

type ShopType = {
  isGemPagesV6: boolean;
  isGemPagesMixed: boolean;
  isGemPagesV7: boolean;
};
type ShopMetaType = {
  isFetchingDataSurvey: boolean;
  dataSurveyShown: TSurveysShown[];
  dropElementCount: number;
  isPreviewed: boolean;
  createThemeSectionCount: number;
  isClosedIntroduceThemeSection: boolean;
  compressImage: boolean;
};

const IS_GEMPAGES_V6_VALUE = 'false';
const IS_GEMPAGES_V7_VALUE = 'true';
const IS_GEMPAGES_MIXED_VALUE = 'mixed';
const ILP_PLAN_LIMIT_DATE = dayjs('2024-12-10T00:00:00.000Z');
export type State = {
  connectedType: 'storefront' | 'instant' | 'gempagesv7' | 'editor';
  shop: ShopSelectFragment | undefined;
  isAdminLogin?: boolean;
  shopId?: string;
  shopIdGempagesV7?: string;
  token?: string;
  shopDomain?: string;
  userHash?: string;
  status?: boolean;
  firstName?: string;
  isGemPagesV7?: string;
  shopType: ShopType;
  shopMeta: ShopMetaType;
  isLatestVersion: boolean;
  isUpgradingVersion: boolean;
  plan?: string;
  shopifyPlan?: string;
  totalILPCount?: number;
};

export const useShopStore = defineStore('shop', {
  state: (): State => ({
    connectedType: 'storefront',
    isAdminLogin: false,
    shop: undefined,
    shopId: undefined,
    token: undefined,
    shopDomain: undefined,
    userHash: undefined,
    status: false,
    firstName: undefined,
    isGemPagesV7: 'true',
    shopType: {
      isGemPagesV6: false,
      isGemPagesMixed: false,
      isGemPagesV7: false,
    },
    shopMeta: {
      isFetchingDataSurvey: false,
      dataSurveyShown: [],
      dropElementCount: 0,
      createThemeSectionCount: 0,
      isClosedIntroduceThemeSection: false,
      isPreviewed: false,
      compressImage: false,
    },
    isLatestVersion: false,
    isUpgradingVersion: false,
    plan: '',
    shopifyPlan: '',
    totalILPCount: 0,
  }),
  getters: {
    getConnectedType(state: State) {
      return state.connectedType;
    },
    getShopId(state: State) {
      return state.shopId;
    },
    getShopIdGempagesV7(state: State) {
      return state.shopIdGempagesV7;
    },
    getToken(state: State) {
      return state.token;
    },
    getUserHash(state: State) {
      return state.userHash;
    },
    getStatus(state: State) {
      return state.status;
    },
    getShop(state: State) {
      return state.shop;
    },
    getFirstName(state: State) {
      return state.firstName;
    },
    getShopDomain(state: State) {
      return state.shopDomain;
    },
    getIsGemPagesV6(state: State) {
      return state.shopType.isGemPagesV6;
    },
    getIsGemPagesMixed(state: State) {
      return state.shopType.isGemPagesMixed;
    },
    getIsGemPagesV7(state: State) {
      return state.shopType.isGemPagesV7;
    },
    getIsFetchingDataSurvey(state) {
      return state.shopMeta.isFetchingDataSurvey;
    },
    getCompressImage(state) {
      return state.shopMeta.compressImage;
    },
    getDataSurveyShown(state) {
      return state.shopMeta.dataSurveyShown;
    },
    getDropElementCount(state) {
      return state.shopMeta.dropElementCount;
    },
    getIsPreviewed(state) {
      return state.shopMeta.isPreviewed;
    },
    getCreateThemeSectionCount(state) {
      return state.shopMeta.createThemeSectionCount;
    },
    getIsClosedIntroduceThemeSection(state) {
      return state.shopMeta.isClosedIntroduceThemeSection;
    },
    getIsLatestVersion(state) {
      return state.isLatestVersion;
    },
    getIsUpgradingVersion(state) {
      return state.isUpgradingVersion;
    },
    getShopPlan(state) {
      return state.plan;
    },
    getShopifyPlan(state) {
      return state.shopifyPlan;
    },
    getIsFreePlan(state) {
      return state.plan === PlanKey.TRIAL_2022 || state.plan === PlanKey.TRIAL;
    },
    getIsStarterPlan(state) {
      return state.plan === PlanKey.STARTER;
    },
    getIsBuildPlan(state) {
      return state.plan === PlanKey.BUILD;
    },
    getIsOptimizePlan(state) {
      return state.plan === PlanKey.OPTIMIZE;
    },
    getIsOptimizePlanAndAbove(state) {
      return [PlanKey.OPTIMIZE, PlanKey.ADVANCED, PlanKey.ENTERPRISE].includes(state.plan as PlanKey);
    },
    getTotalILPCount(state) {
      return state.totalILPCount;
    },
    getCanAccessILP(state) {
      return (
        [PlanKey.OPTIMIZE, PlanKey.ADVANCED, PlanKey.ENTERPRISE].includes(state.plan as PlanKey) ||
        (!!state.totalILPCount &&
          state.plan === PlanKey.BUILD &&
          dayjs(state.shop?.createdAt).isBefore(ILP_PLAN_LIMIT_DATE))
      );
    },
    getIsShopifyStarterPlan(state) {
      return state.shopifyPlan == 'Shopify Starter';
    },
  },
  actions: {
    setConnectedType(data: 'storefront' | 'instant' | 'gempagesv7' | 'editor') {
      this.connectedType = data;
    },
    setIsAdminLogin(data: boolean) {
      this.isAdminLogin = data;
    },
    setShop(data: ShopSelectFragment | undefined) {
      this.shop = data;
    },
    setShopDomain(data: string | undefined) {
      this.shopDomain = data;
    },
    setShopId(data: string | undefined) {
      this.shopId = data;
    },
    setShopIdGempagesV7(data: string | undefined) {
      this.shopIdGempagesV7 = data;
    },
    setToken(data: string | undefined) {
      this.token = data;
    },
    setUserHash(data: string | undefined) {
      this.userHash = data;
    },
    setStatus(status: boolean) {
      this.status = status;
    },
    setFirstName(firstName: string | undefined) {
      this.firstName = firstName;
    },
    setIsGemPagesV7(isGemPagesV7: string | undefined) {
      this.isGemPagesV7 = isGemPagesV7;
    },
    setShopType(isGemPagesV7: string | undefined) {
      this.shopType.isGemPagesV6 = isGemPagesV7 == IS_GEMPAGES_V6_VALUE;
      this.shopType.isGemPagesMixed = isGemPagesV7 == IS_GEMPAGES_MIXED_VALUE;
      this.shopType.isGemPagesV7 = isGemPagesV7 == IS_GEMPAGES_V7_VALUE;
    },
    setIsFetchingDataSurvey(payload: boolean) {
      this.shopMeta.isFetchingDataSurvey = payload;
    },
    setDataSurveyShown(payload: TSurveysShown[]) {
      this.shopMeta.dataSurveyShown = payload;
    },
    setDropElementCount(payload: number) {
      this.shopMeta.dropElementCount = payload;
    },
    setIsPreviewed() {
      this.shopMeta.isPreviewed = true;
    },
    setCompressImage(value: boolean) {
      this.shopMeta.compressImage = value;
    },
    setIsLatestVersion(value: boolean) {
      this.isLatestVersion = value;
    },
    setIsUpgradingVersion(value: boolean) {
      this.isUpgradingVersion = value;
    },
    setCreateThemeSectionCount(payload: number) {
      this.shopMeta.createThemeSectionCount = payload;
    },
    setIsClosedIntroduceThemeSection(payload: boolean) {
      this.shopMeta.isClosedIntroduceThemeSection = payload;
    },
    setShopPlan(value: string | undefined) {
      this.plan = value;
    },
    setShopifyPlan(value: string | undefined) {
      this.shopifyPlan = value;
    },
    setTotalIlPCount(value: number | undefined) {
      this.totalILPCount = value;
    },
  },
});

export default useShopStore;
