import { ConditionQuantity } from '../types';

export const conditionOfQuantity = [
  ConditionQuantity.EQUAL,
  ConditionQuantity.GREATER,
  ConditionQuantity.LESS,
  ConditionQuantity.GREATER_OR_EQUAL,
  ConditionQuantity.LESS_OR_EQUAL,
  ConditionQuantity.BETWEEN,
];

export const conditionOfCountdown = [
  ConditionQuantity.EQUAL,
  ConditionQuantity.GREATER,
  ConditionQuantity.LESS,
  ConditionQuantity.GREATER_OR_EQUAL,
  ConditionQuantity.LESS_OR_EQUAL,
  ConditionQuantity.BETWEEN,
  ConditionQuantity.OUT_OF_TIME,
];

export const conditionOfStock = [
  ConditionQuantity.EQUAL,
  ConditionQuantity.GREATER,
  ConditionQuantity.LESS,
  ConditionQuantity.GREATER_OR_EQUAL,
  ConditionQuantity.LESS_OR_EQUAL,
  ConditionQuantity.BETWEEN,
  ConditionQuantity.OUT_OF_STOCK,
];

export const triggerConditionOptions = [
  {
    name: 'Equal',
    value: ConditionQuantity.EQUAL,
    id: ConditionQuantity.EQUAL,
  },
  {
    name: 'Greater',
    value: ConditionQuantity.GREATER,
    id: ConditionQuantity.GREATER,
  },
  {
    name: 'Less',
    value: ConditionQuantity.LESS,
    id: ConditionQuantity.LESS,
  },
  {
    name: 'Greater or Equal',
    value: ConditionQuantity.GREATER_OR_EQUAL,
    id: ConditionQuantity.GREATER_OR_EQUAL,
  },
  {
    name: 'Less or Equal',
    value: ConditionQuantity.LESS_OR_EQUAL,
    id: ConditionQuantity.LESS_OR_EQUAL,
  },
  {
    name: 'Between',
    value: ConditionQuantity.BETWEEN,
    id: ConditionQuantity.BETWEEN,
  },
  {
    name: 'Out of stock',
    value: ConditionQuantity.OUT_OF_STOCK,
    id: ConditionQuantity.OUT_OF_STOCK,
  },
  {
    name: 'Out of time',
    value: ConditionQuantity.OUT_OF_TIME,
    id: ConditionQuantity.OUT_OF_TIME,
  },
];

export const conditionMappingElementTag: Record<string, ConditionQuantity[]> = {
  ProductQuantity: [...conditionOfQuantity],
  ProductPrice: [...conditionOfQuantity],
  StockCounter: [...conditionOfStock],
  Countdown: [...conditionOfCountdown],
};
