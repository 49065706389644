import type * as Types from '@/types/graphql';

import type { ComputedRef, Ref } from 'vue';
import { useMutation, type UseMutationOptions } from 'vue-query';
import { appFetcher } from '@/api/gatewayClient';
export type ThemePageVersionCreateMutationVariables = Types.Exact<{
  themePageId: Types.Scalars['ID'];
  input: Types.CreateThemePageVersionInput;
}>;

export type ThemePageVersionCreateMutationResponse = {
  themePageVersionCreate?: Types.Maybe<Pick<Types.ThemePageVersion, 'id'>>;
};

export const ThemePageVersionCreateDocument = `
    mutation ThemePageVersionCreate($themePageId: ID!, $input: CreateThemePageVersionInput!) {
  themePageVersionCreate(themePageID: $themePageId, input: $input) {
    id
  }
}
    `;
export const useThemePageVersionCreateMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    ThemePageVersionCreateMutationResponse,
    TError,
    ThemePageVersionCreateMutationVariables,
    TContext
  >,
) =>
  useMutation<ThemePageVersionCreateMutationResponse, TError, ThemePageVersionCreateMutationVariables, TContext>(
    ['ThemePageVersionCreate'],
    (
      variables:
        | ThemePageVersionCreateMutationVariables
        | ComputedRef<ThemePageVersionCreateMutationVariables>
        | Ref<ThemePageVersionCreateMutationVariables>,
    ) =>
      appFetcher<ThemePageVersionCreateMutationResponse, ThemePageVersionCreateMutationVariables>(
        ThemePageVersionCreateDocument,
        variables,
      )(),
    options,
  );
useThemePageVersionCreateMutation.getKey = () => ['ThemePageVersionCreate'];
