import type * as Types from '@/types/graphql';

export type FileSelectFragment = Pick<
  Types.File,
  | 'backupFileKey'
  | 'backupFilePath'
  | 'createdAt'
  | 'deletedAt'
  | 'fileKey'
  | 'fileName'
  | 'filePath'
  | 'height'
  | 'id'
  | 'mimeType'
  | 'size'
  | 'updatedAt'
  | 'width'
>;

export const FileSelectFragmentDoc = `
    fragment FileSelect on File {
  backupFileKey
  backupFilePath
  createdAt
  deletedAt
  fileKey
  fileName
  filePath
  height
  id
  mimeType
  size
  updatedAt
  width
}
    `;
