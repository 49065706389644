import type * as Types from '@/types/graphql';

import type { ComputedRef, Ref } from 'vue';
import { useQuery, type UseQueryOptions } from 'vue-query';
import { appFetcher } from '@/api/gatewayClient';
export type ThemePageMetaQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['Cursor']>;
  before?: Types.InputMaybe<Types.Scalars['Cursor']>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  themePageID: Types.Scalars['ID'];
  where?: Types.InputMaybe<Types.ThemePageMetaWhereInput>;
}>;

export type ThemePageMetaQueryResponse = {
  themePageMeta?: Types.Maybe<{
    edges?: Types.Maybe<
      Array<
        Types.Maybe<
          Pick<Types.ThemePageMetaEdge, 'cursor'> & {
            node?: Types.Maybe<Pick<Types.ThemePageMeta, 'createdAt' | 'id' | 'key' | 'updatedAt' | 'value'>>;
          }
        >
      >
    >;
    pageInfo: Pick<Types.PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>;
  }>;
};

export const ThemePageMetaDocument = `
    query themePageMeta($after: Cursor, $before: Cursor, $first: Int, $last: Int, $themePageID: ID!, $where: ThemePageMetaWhereInput) {
  themePageMeta(
    after: $after
    before: $before
    first: $first
    last: $last
    themePageID: $themePageID
    where: $where
  ) {
    edges {
      cursor
      node {
        createdAt
        id
        key
        updatedAt
        value
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
}
    `;
export const useThemePageMetaQuery = <TData = ThemePageMetaQueryResponse, TError = unknown>(
  variables: ThemePageMetaQueryVariables | ComputedRef<ThemePageMetaQueryVariables> | Ref<ThemePageMetaQueryVariables>,
  options?: UseQueryOptions<ThemePageMetaQueryResponse, TError, TData>,
) =>
  useQuery<ThemePageMetaQueryResponse, TError, TData>(
    ['themePageMeta', variables],
    appFetcher<ThemePageMetaQueryResponse, ThemePageMetaQueryVariables>(ThemePageMetaDocument, variables),
    options,
  );

useThemePageMetaQuery.getKey = (
  variables: ThemePageMetaQueryVariables | ComputedRef<ThemePageMetaQueryVariables> | Ref<ThemePageMetaQueryVariables>,
) => ['themePageMeta', variables];
