import type * as Types from '@/types/graphql';

export type PageSectionSelectFragment = Pick<
  Types.PageSection,
  'id' | 'cid' | 'name' | 'display' | 'component' | 'createdAt' | 'updatedAt' | 'deletedAt' | 'isMobile'
> & { librarySection?: Types.Maybe<Pick<Types.LibrarySection, 'id'>> };

export const PageSectionSelectFragmentDoc = `
    fragment PageSectionSelect on PageSection {
  id
  cid
  name
  display
  component
  createdAt
  updatedAt
  deletedAt
  isMobile
  librarySection {
    id
  }
}
    `;
