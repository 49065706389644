import localForage from 'localforage';
import * as actions from './action';
import useEditorStore from '../../common/stores/editor';
import { historyCreate, historyRedo, historySetIndex } from './history';
import { sentryCaptureException } from '../../common/use-cases/sentry';
const store = localForage.createInstance({
  name: 'history',
});
const historyKeys = `histories-keys`;

// === Handle localStorage ===
export const setHistoryToLocal = async (key: string, data: string) => {
  const strHistoryKeys: string | null = await store.getItem(historyKeys);
  let keys: string[] = [];
  if (strHistoryKeys) {
    keys = strHistoryKeys.split(',');
  }

  // Clear with limit = 10
  if (keys.length > 10 && !keys.includes(key)) {
    const oldKey = keys.splice(0, 1); // splice 1 item
    const localKey = `histories-${oldKey}`;
    store.removeItem(localKey).catch(function (err) {
      sentryCaptureException(
        'clearHistoryLocal',
        'Remove old data width key',
        {
          err,
        },
        {
          level: 'error',
        },
      );
    });
  }

  // Update data to storage
  const localKey = `histories-${key}`;
  store.setItem(localKey, data).catch((err) => {
    sentryCaptureException(
      'setHistoryToLocal',
      'save data error',
      {
        err,
      },
      {
        level: 'error',
      },
    );
  });

  // Update current key to keys
  if (!keys.includes(key)) {
    keys.push(key);
    store.setItem(historyKeys, keys.join(',')).catch((err) => {
      sentryCaptureException(
        'setHistoryToLocal',
        'set keys to local',
        {
          err,
        },
        {
          level: 'error',
        },
      );
    });
  }
};
export const getHistoryLocal = async (key: string) => {
  const strHistoryKeys: string | null = await store.getItem(historyKeys);
  let keys: string[] = [];
  if (strHistoryKeys) {
    keys = strHistoryKeys.split(',');
  }

  if (keys.includes(key)) {
    const localKey = `histories-${key}`;
    const data = await store.getItem(localKey).catch(function (err) {
      sentryCaptureException(
        'getHistoryLocal',
        'Get history error',
        {
          err,
        },
        {
          level: 'error',
        },
      );
    });
    return data;
  }
  return '[]';
};
export const clearHistoryLocal = async (key: string) => {
  const strHistoryKeys: string | null = await store.getItem(historyKeys);
  let keys: string[] = [];
  if (strHistoryKeys) {
    keys = strHistoryKeys.split(',');
  }
  // Clear key
  if (keys.includes(key)) {
    const index = keys.indexOf(key);
    const oldKey = keys.splice(index, 1); // splice 1 item width index
    const localKey = `histories-${oldKey}`;
    store.removeItem(localKey).catch(function (err) {
      sentryCaptureException(
        'clearHistoryLocal',
        'remove old data width key',
        {
          err,
        },
        {
          level: 'error',
        },
      );
    });

    store.setItem(historyKeys, keys.join(',')).catch((err) => {
      sentryCaptureException(
        'clearHistoryLocal',
        'set keys to local',
        {
          err,
        },
        {
          level: 'error',
        },
      );
    });
  }
};
// === End handle localStorage ===

export const historyCheckRecovery = async () => {
  try {
    const editorStore = useEditorStore();
    const key = `${editorStore.getEditingThemeId}-${editorStore.getEditingPageId}`;
    const strHistories = await getHistoryLocal(key);
    const histories = JSON.parse(strHistories as string);
    if (histories?.length) {
      return true;
    }
  } catch (e) {
    sentryCaptureException(
      'historyCheckRecovery',
      'Error get history and parse',
      {
        e,
      },
      {
        level: 'error',
      },
    );
    return false;
  }
  return false;
};

export const historyRestoreLocalStorage = async () => {
  const editorStore = useEditorStore();
  const key = `${editorStore.getEditingThemeId}-${editorStore.getEditingPageId}`;
  const strHistories = await getHistoryLocal(key);

  let histories = [];
  try {
    histories = JSON.parse(strHistories as string);
  } catch (e) {
    sentryCaptureException(
      'historyRestoreLocalStorage',
      'Error get history and parse',
      {
        e,
      },
      {
        level: 'error',
      },
    );
  }
  if (histories?.length) {
    for (let i = 0; i < histories.length; i++) {
      const itemHistory = histories[i];
      const data = itemHistory.data;
      if (data?.func) {
        await historyCreate((actions as any)[data.func](data.data));
      }
    }
    if (histories?.length) {
      historySetIndex(-1);
      for (let i = 0; i < histories.length; i++) {
        await historyRedo({
          noRecordHistory: true,
          noApplyToPreview: true,
        });
      }
    }
  }
};

export const historyClearLocalStorage = () => {
  const editorStore = useEditorStore();
  const key = `${editorStore.getEditingThemeId}-${editorStore.getEditingPageId}`;
  clearHistoryLocal(key);
};
