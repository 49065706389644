import { defineStore } from 'pinia';
import { SectionErrorDetail } from '../type';

export const useErrorCollectorStore = defineStore('error-collector', {
  state: () => ({
    errorMap: new Map<string, number>(),
    errorDetail: new Map<string, object>(),
  }),
  actions: {
    addError(error: string, errorDetail?: SectionErrorDetail) {
      if (this.errorMap.has(error)) {
        this.errorMap.set(error, (this.errorMap.get(error) as number) + 1);
      } else {
        this.errorMap.set(error, 1);
      }

      if (errorDetail) {
        this.errorDetail.set(errorDetail.cid, errorDetail);
      }
    },
    clearError() {
      this.errorMap.clear();
    },
    convertErrorToObject() {
      return Object.fromEntries(this.errorMap);
    },
    countTotalError() {
      return Array.from(this.errorMap.values()).reduce((acc, curr) => acc + curr, 0);
    },
    hasError() {
      return this.errorMap.size;
    },
  },
});
