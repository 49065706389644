import type * as Types from '@/types/graphql';

import type { ComputedRef, Ref } from 'vue';
import { useMutation, type UseMutationOptions } from 'vue-query';
import { appFetcher } from '@/api/gatewayClient';
export type PageSectionsBulkDeleteMutationVariables = Types.Exact<{
  sectionIDs: Array<Types.Scalars['ID']>;
  themePageID: Types.Scalars['ID'];
}>;

export type PageSectionsBulkDeleteMutationResponse = Pick<Types.Mutation, 'pageSectionsBulkDelete'>;

export const PageSectionsBulkDeleteDocument = `
    mutation pageSectionsBulkDelete($sectionIDs: [ID!]!, $themePageID: ID!) {
  pageSectionsBulkDelete(sectionIDs: $sectionIDs, themePageID: $themePageID)
}
    `;
export const usePageSectionsBulkDeleteMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    PageSectionsBulkDeleteMutationResponse,
    TError,
    PageSectionsBulkDeleteMutationVariables,
    TContext
  >,
) =>
  useMutation<PageSectionsBulkDeleteMutationResponse, TError, PageSectionsBulkDeleteMutationVariables, TContext>(
    ['pageSectionsBulkDelete'],
    (
      variables:
        | PageSectionsBulkDeleteMutationVariables
        | ComputedRef<PageSectionsBulkDeleteMutationVariables>
        | Ref<PageSectionsBulkDeleteMutationVariables>,
    ) =>
      appFetcher<PageSectionsBulkDeleteMutationResponse, PageSectionsBulkDeleteMutationVariables>(
        PageSectionsBulkDeleteDocument,
        variables,
      )(),
    options,
  );
usePageSectionsBulkDeleteMutation.getKey = () => ['pageSectionsBulkDelete'];
