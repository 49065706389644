import { defineStore } from 'pinia';
import type { Section } from '../../common/utils/types';
import type { DetectionJob } from '../types/image2layout';

type AnchorType = {
  cid: string | undefined;
  direction: 'before' | 'after';
};

export type State = {
  anchor: AnchorType;
  generatingSections: Section[];
  isOpenModalProgress: boolean;
  link: string;
  file: File | null;
  generateType: 'image' | 'url' | '';
  generatedSections: Section[];
  generatedErrorSections: {
    [x: string]: Section;
  };
  detectError: string;
  detectedSectionsUrl: string[];
  detectFrom: 'main' | 'panel' | '' | 'tool-bar';
  isDetectUrl: boolean;
  session: number;
  generatingUrlTracking: {
    [x: string]: {
      urls: string[];
      error: number;
      success: number;
      sentTracking: boolean;
      generateFrom: string;
    };
  };
  detectionJobs: DetectionJob[];
  activeTabId: string;
  activeStep: 'SELECT_FILE' | 'DETECTING' | 'DONE';
};

export const useImageToLayoutStore = defineStore('imageToLayout', {
  state: (): State => ({
    anchor: {
      cid: undefined,
      direction: 'after',
    },
    generatingSections: [],
    isOpenModalProgress: false,
    link: '',
    file: null,
    generateType: '',
    generatedSections: [],
    generatedErrorSections: {},
    detectError: '',
    detectedSectionsUrl: [],
    detectFrom: '',
    isDetectUrl: false,
    session: 1,
    generatingUrlTracking: {},
    detectionJobs: [],
    activeTabId: '1',
    activeStep: 'SELECT_FILE',
  }),
  getters: {
    getAnchor(state) {
      return state.anchor;
    },
    getGeneratingSections(state) {
      return state.generatingSections;
    },
    getIsOpenModalProgress(state) {
      return state.isOpenModalProgress;
    },
    getLink(state) {
      return state.link;
    },
    getFile(state) {
      return state.file;
    },
    getGenerateType(state) {
      return state.generateType;
    },
    getGeneratedSections(state) {
      return state.generatedSections;
    },
    getGeneratedErrorSections(state) {
      return state.generatedErrorSections;
    },
    isGenerating(state) {
      return state.generatingSections.length > 0;
    },
    getDetectError(state) {
      return state.detectError;
    },
    getDetectedSectionsUrl(state) {
      return state.detectedSectionsUrl;
    },
    getDetectFrom(state) {
      return state.detectFrom;
    },
    getIsDetectUrl(state) {
      return state.isDetectUrl;
    },
    getSession(state) {
      return state.session;
    },
    getGeneratingUrlTracking(state) {
      return state.generatingUrlTracking;
    },
    getDetectionJobs(state) {
      return state.detectionJobs;
    },
    getActiveTabId(state) {
      return state.activeTabId;
    },
    getActiveStep(state) {
      return state.activeStep;
    },
  },
  actions: {
    clear() {
      this.$reset();
    },
    resetAnchor() {
      this.anchor.cid = undefined;
      this.anchor.direction = 'after';
    },
    setAnchor({ cid, direction }: AnchorType) {
      this.anchor.cid = cid;
      this.anchor.direction = direction;
    },
    setGeneratingSections(sections: Section[]) {
      this.generatingSections = sections;
    },
    setIsOpenModalProgress(isOpen: boolean) {
      this.isOpenModalProgress = isOpen;
    },
    setLink(link: string) {
      this.link = link;
    },
    setFile(file: File | null) {
      this.file = file;
    },
    setGenerateType(type: 'image' | 'url' | '') {
      this.generateType = type;
    },
    setGeneratedSections(sections: Section[]) {
      this.generatedSections = sections;
    },
    setGeneratedErrorSections(sections: Section) {
      this.generatedErrorSections = {
        ...this.generatedErrorSections,
        [sections.cid || '']: sections,
      };
    },
    setDetectError(error: string) {
      this.detectError = error;
    },
    setDetectedSectionsUrl(sections: string[]) {
      this.detectedSectionsUrl = sections;
    },
    setDetectFrom(from: 'main' | 'panel' | '' | 'tool-bar') {
      this.detectFrom = from;
    },
    setIsDetectUrl(isDetect: boolean) {
      this.isDetectUrl = isDetect;
    },
    setSession() {
      this.session = this.session + 1;
    },
    clearData() {
      this.setDetectError('');
      this.setDetectedSectionsUrl([]);
      this.setDetectFrom('');
      this.setIsDetectUrl(false);
      this.setFile(null);
    },
    setUrlsForGeneratingUrlTracking(urls: string[], section: string, generateFrom: string) {
      this.generatingUrlTracking = {
        ...this.generatingUrlTracking,
        [section]: {
          urls,
          success: 0,
          error: 0,
          sentTracking: false,
          generateFrom,
        },
      };
    },

    setCountForGeneratingUrlTracking(isSuccess: boolean, section: string) {
      const tracking = this.generatingUrlTracking[section] || {};
      const newSuccess = isSuccess ? tracking.success + 1 : tracking.success;
      const newError = !isSuccess ? tracking.error + 1 : tracking.error;
      const sentTracking = newSuccess + newError === tracking.urls.length;
      this.generatingUrlTracking = {
        ...this.generatingUrlTracking,
        [section]: {
          ...tracking,
          success: newSuccess,
          error: newError,
          sentTracking,
        },
      };
    },
    setDetectionJobs(jobs: DetectionJob[]) {
      this.detectionJobs = this.detectionJobs.concat(jobs);
    },
    setActiveTabId(id: string) {
      this.activeTabId = id;
    },
    setActiveStep(step: 'SELECT_FILE' | 'DETECTING' | 'DONE') {
      this.activeStep = step;
    },
  },
});

export default useImageToLayoutStore;
