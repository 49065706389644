import type * as Types from '@/types/graphql';

import type { ComputedRef, Ref } from 'vue';
import { useMutation, type UseMutationOptions } from 'vue-query';
import { appFetcher } from '@/api/gatewayClient';
export type ThemePageAddThemeSectionsMutationVariables = Types.Exact<{
  themeSectionIDs: Array<Types.Scalars['ID']>;
  id: Types.Scalars['ID'];
}>;

export type ThemePageAddThemeSectionsMutationResponse = Pick<Types.Mutation, 'themePageAddThemeSections'>;

export const ThemePageAddThemeSectionsDocument = `
    mutation themePageAddThemeSections($themeSectionIDs: [ID!]!, $id: ID!) {
  themePageAddThemeSections(themeSectionIDs: $themeSectionIDs, id: $id)
}
    `;
export const useThemePageAddThemeSectionsMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    ThemePageAddThemeSectionsMutationResponse,
    TError,
    ThemePageAddThemeSectionsMutationVariables,
    TContext
  >,
) =>
  useMutation<ThemePageAddThemeSectionsMutationResponse, TError, ThemePageAddThemeSectionsMutationVariables, TContext>(
    ['themePageAddThemeSections'],
    (
      variables:
        | ThemePageAddThemeSectionsMutationVariables
        | ComputedRef<ThemePageAddThemeSectionsMutationVariables>
        | Ref<ThemePageAddThemeSectionsMutationVariables>,
    ) =>
      appFetcher<ThemePageAddThemeSectionsMutationResponse, ThemePageAddThemeSectionsMutationVariables>(
        ThemePageAddThemeSectionsDocument,
        variables,
      )(),
    options,
  );
useThemePageAddThemeSectionsMutation.getKey = () => ['themePageAddThemeSections'];
