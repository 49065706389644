import type * as Types from '@/types/graphql';

import type { ComputedRef, Ref } from 'vue';
import { useMutation, type UseMutationOptions } from 'vue-query';
import { appFetcher } from '@/api/gatewayClient';
export type ThemePageUpdateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input: Types.UpdateThemePageInput;
}>;

export type ThemePageUpdateMutationResponse = {
  themePageUpdate?: Types.Maybe<
    Pick<
      Types.ThemePage,
      'createdAt' | 'default' | 'description' | 'id' | 'name' | 'splitPercentage' | 'status' | 'type' | 'updatedAt'
    >
  >;
};

export const ThemePageUpdateDocument = `
    mutation themePageUpdate($id: ID!, $input: UpdateThemePageInput!) {
  themePageUpdate(id: $id, input: $input) {
    createdAt
    default
    description
    id
    name
    splitPercentage
    status
    type
    updatedAt
    status
  }
}
    `;
export const useThemePageUpdateMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<ThemePageUpdateMutationResponse, TError, ThemePageUpdateMutationVariables, TContext>,
) =>
  useMutation<ThemePageUpdateMutationResponse, TError, ThemePageUpdateMutationVariables, TContext>(
    ['themePageUpdate'],
    (
      variables:
        | ThemePageUpdateMutationVariables
        | ComputedRef<ThemePageUpdateMutationVariables>
        | Ref<ThemePageUpdateMutationVariables>,
    ) =>
      appFetcher<ThemePageUpdateMutationResponse, ThemePageUpdateMutationVariables>(
        ThemePageUpdateDocument,
        variables,
      )(),
    options,
  );
useThemePageUpdateMutation.getKey = () => ['themePageUpdate'];
