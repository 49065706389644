import type * as Types from '@/types/graphql';

import type { ComputedRef, Ref } from 'vue';
import { useQuery, type UseQueryOptions } from 'vue-query';
import { appFetcher } from '@/api/gatewayClient';
export type ThemePagesQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.ThemePageWhereInput>;
}>;

export type ThemePagesQueryResponse = { themePages?: Types.Maybe<Pick<Types.ThemePageConnection, 'totalCount'>> };

export const ThemePagesDocument = `
    query ThemePages($where: ThemePageWhereInput) {
  themePages(where: $where) {
    totalCount
  }
}
    `;
export const useThemePagesQuery = <TData = ThemePagesQueryResponse, TError = unknown>(
  variables?: ThemePagesQueryVariables | ComputedRef<ThemePagesQueryVariables> | Ref<ThemePagesQueryVariables>,
  options?: UseQueryOptions<ThemePagesQueryResponse, TError, TData>,
) =>
  useQuery<ThemePagesQueryResponse, TError, TData>(
    variables === undefined ? ['ThemePages'] : ['ThemePages', variables],
    appFetcher<ThemePagesQueryResponse, ThemePagesQueryVariables>(ThemePagesDocument, variables),
    options,
  );

useThemePagesQuery.getKey = (
  variables?: ThemePagesQueryVariables | ComputedRef<ThemePagesQueryVariables> | Ref<ThemePagesQueryVariables>,
) => (variables === undefined ? ['ThemePages'] : ['ThemePages', variables]);
