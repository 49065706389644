import type * as Types from '@/types/graphql';

import type { ComputedRef, Ref } from 'vue';
import { useMutation, type UseMutationOptions } from 'vue-query';
import { appFetcher } from '@/api/gatewayClient';
export type PageSectionMetaCreateOrUpdateMutationVariables = Types.Exact<{
  pageSectionId: Types.Scalars['ID'];
  input: Types.UpsertPageSectionMetaInput;
}>;

export type PageSectionMetaCreateOrUpdateMutationResponse = {
  pageSectionMetaCreateOrUpdate?: Types.Maybe<Pick<Types.PageSectionMeta, 'id'>>;
};

export const PageSectionMetaCreateOrUpdateDocument = `
    mutation PageSectionMetaCreateOrUpdate($pageSectionId: ID!, $input: UpsertPageSectionMetaInput!) {
  pageSectionMetaCreateOrUpdate(pageSectionID: $pageSectionId, input: $input) {
    id
  }
}
    `;
export const usePageSectionMetaCreateOrUpdateMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    PageSectionMetaCreateOrUpdateMutationResponse,
    TError,
    PageSectionMetaCreateOrUpdateMutationVariables,
    TContext
  >,
) =>
  useMutation<
    PageSectionMetaCreateOrUpdateMutationResponse,
    TError,
    PageSectionMetaCreateOrUpdateMutationVariables,
    TContext
  >(
    ['PageSectionMetaCreateOrUpdate'],
    (
      variables:
        | PageSectionMetaCreateOrUpdateMutationVariables
        | ComputedRef<PageSectionMetaCreateOrUpdateMutationVariables>
        | Ref<PageSectionMetaCreateOrUpdateMutationVariables>,
    ) =>
      appFetcher<PageSectionMetaCreateOrUpdateMutationResponse, PageSectionMetaCreateOrUpdateMutationVariables>(
        PageSectionMetaCreateOrUpdateDocument,
        variables,
      )(),
    options,
  );
usePageSectionMetaCreateOrUpdateMutation.getKey = () => ['PageSectionMetaCreateOrUpdate'];
