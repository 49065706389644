import type * as Types from '@/types/graphql';

import type { ComputedRef, Ref } from 'vue';
import { ThemeSectionSelectFragmentDoc } from '../fragments/themeSection.generated';
import { useMutation, type UseMutationOptions } from 'vue-query';
import { appFetcher } from '@/api/gatewayClient';
export type ThemeSectionUpdateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input?: Types.InputMaybe<Types.UpdateThemeSectionInput>;
}>;

export type ThemeSectionUpdateMutationResponse = {
  themeSectionUpdate?: Types.Maybe<
    Pick<
      Types.ThemeSection,
      'id' | 'name' | 'display' | 'isMobile' | 'createdAt' | 'updatedAt' | 'publishedAt' | 'component' | 'status'
    >
  >;
};

export const ThemeSectionUpdateDocument = `
    mutation themeSectionUpdate($id: ID!, $input: UpdateThemeSectionInput) {
  themeSectionUpdate(id: $id, input: $input) {
    ...ThemeSectionSelect
  }
}
    ${ThemeSectionSelectFragmentDoc}`;
export const useThemeSectionUpdateMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    ThemeSectionUpdateMutationResponse,
    TError,
    ThemeSectionUpdateMutationVariables,
    TContext
  >,
) =>
  useMutation<ThemeSectionUpdateMutationResponse, TError, ThemeSectionUpdateMutationVariables, TContext>(
    ['themeSectionUpdate'],
    (
      variables:
        | ThemeSectionUpdateMutationVariables
        | ComputedRef<ThemeSectionUpdateMutationVariables>
        | Ref<ThemeSectionUpdateMutationVariables>,
    ) =>
      appFetcher<ThemeSectionUpdateMutationResponse, ThemeSectionUpdateMutationVariables>(
        ThemeSectionUpdateDocument,
        variables,
      )(),
    options,
  );
useThemeSectionUpdateMutation.getKey = () => ['themeSectionUpdate'];
