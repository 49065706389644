// eslint-disable-next-line check-file/filename-naming-convention
import { defineStore } from 'pinia';

export const useSaveStatusStore = defineStore('save-status', {
  state: () => ({
    isRunningSaveAPI: false,
    retryCount: 0,
  }),
  actions: {
    setIsRunningSaveAPI(isRunning: boolean) {
      this.isRunningSaveAPI = isRunning;
    },
    incrementRetryCount() {
      this.retryCount += 1;
    },
    resetRetryCount() {
      this.retryCount = 0;
    },
  },
});
