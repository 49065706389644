import type * as Types from '@/types/graphql';

import type { ComputedRef, Ref } from 'vue';
import { useMutation, type UseMutationOptions } from 'vue-query';
import { appFetcher } from '@/api/gatewayClient';

export type PlatformOriginalTemplateUpsertMutationResponse = {
  platformOriginalTemplateUpsert?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.CreatePlatformOriginalTemplateInput, 'content'>>>
  >;
};

export const PlatformOriginalTemplateUpsertDocument = `
  mutation PlatformOriginalTemplateUpsert($themePageID: ID!, $input: CreatePlatformOriginalTemplateInput!) {
    platformOriginalTemplateUpsert(themePageID: $themePageID, input: $input) {
      content
      id
    }
  }`;
export const usePlatformOriginalTemplateUpsertMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    PlatformOriginalTemplateUpsertMutationResponse,
    TError,
    Types.MutationPlatformOriginalTemplateUpsertArgs,
    TContext
  >,
) =>
  useMutation<
    PlatformOriginalTemplateUpsertMutationResponse,
    TError,
    Types.MutationPlatformOriginalTemplateUpsertArgs,
    TContext
  >(
    ['PlatformOriginalTemplateUpsert'],
    (
      variables:
        | Types.MutationPlatformOriginalTemplateUpsertArgs
        | ComputedRef<Types.MutationPlatformOriginalTemplateUpsertArgs>
        | Ref<Types.MutationPlatformOriginalTemplateUpsertArgs>,
    ) =>
      appFetcher<PlatformOriginalTemplateUpsertMutationResponse, Types.MutationPlatformOriginalTemplateUpsertArgs>(
        PlatformOriginalTemplateUpsertDocument,
        variables,
      )(),
    options,
  );
usePlatformOriginalTemplateUpsertMutation.getKey = () => ['PlatformOriginalTemplateUpsert'];
