import type * as Types from '@/types/graphql';

import type { ComputedRef, Ref } from 'vue';
import { PageSectionSelectFragmentDoc } from '../fragments/page-section.generated';
import { useMutation, type UseMutationOptions } from 'vue-query';
import { appFetcher } from '@/api/gatewayClient';
export type PageSectionUpdateMutationVariables = Types.Exact<{
  input: Types.UpdatePageSectionInput;
  id: Types.Scalars['ID'];
}>;

export type PageSectionUpdateMutationResponse = {
  pageSectionUpdate?: Types.Maybe<
    Pick<
      Types.PageSection,
      'id' | 'cid' | 'name' | 'display' | 'component' | 'createdAt' | 'updatedAt' | 'deletedAt' | 'isMobile'
    > & { librarySection?: Types.Maybe<Pick<Types.LibrarySection, 'id'>> }
  >;
};

export const PageSectionUpdateDocument = `
    mutation pageSectionUpdate($input: UpdatePageSectionInput!, $id: ID!) {
  pageSectionUpdate(input: $input, id: $id) {
    ...PageSectionSelect
  }
}
    ${PageSectionSelectFragmentDoc}`;
export const usePageSectionUpdateMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<PageSectionUpdateMutationResponse, TError, PageSectionUpdateMutationVariables, TContext>,
) =>
  useMutation<PageSectionUpdateMutationResponse, TError, PageSectionUpdateMutationVariables, TContext>(
    ['pageSectionUpdate'],
    (
      variables:
        | PageSectionUpdateMutationVariables
        | ComputedRef<PageSectionUpdateMutationVariables>
        | Ref<PageSectionUpdateMutationVariables>,
    ) =>
      appFetcher<PageSectionUpdateMutationResponse, PageSectionUpdateMutationVariables>(
        PageSectionUpdateDocument,
        variables,
      )(),
    options,
  );
usePageSectionUpdateMutation.getKey = () => ['pageSectionUpdate'];
