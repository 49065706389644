import { useThemePagesQuery } from '@/api/app/themePagesCount.generated';
import useNotification from '@/composables/useNotification';
import useShopStore from '@/stores/shop';
const { handleError } = useNotification();

const useILPTotalCount = () => {
  const shopStore = useShopStore();
  return useThemePagesQuery(
    {
      where: {
        type: 'STATIC',
      },
    },
    {
      refetchOnMount: true,
      staleTime: 5000,
      onError: handleError,
      onSuccess: (result) => {
        shopStore.setTotalIlPCount(result.themePages?.totalCount ?? 0);
      },
    },
  );
};

export default useILPTotalCount;
