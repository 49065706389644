import type * as Types from '@/types/graphql';

import type { ComputedRef, Ref } from 'vue';
import { PageSectionSelectFragmentDoc } from '../fragments/page-section.generated';
import { useMutation, type UseMutationOptions } from 'vue-query';
import { appFetcher } from '@/api/gatewayClient';
export type PageSectionCreateMutationVariables = Types.Exact<{
  input: Types.CreatePageSectionInput;
  themePageID: Types.Scalars['ID'];
}>;

export type PageSectionCreateMutationResponse = {
  pageSectionCreate?: Types.Maybe<
    Pick<
      Types.PageSection,
      'id' | 'cid' | 'name' | 'display' | 'component' | 'createdAt' | 'updatedAt' | 'deletedAt' | 'isMobile'
    > & { librarySection?: Types.Maybe<Pick<Types.LibrarySection, 'id'>> }
  >;
};

export const PageSectionCreateDocument = `
    mutation pageSectionCreate($input: CreatePageSectionInput!, $themePageID: ID!) {
  pageSectionCreate(input: $input, themePageID: $themePageID) {
    ...PageSectionSelect
  }
}
    ${PageSectionSelectFragmentDoc}`;
export const usePageSectionCreateMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<PageSectionCreateMutationResponse, TError, PageSectionCreateMutationVariables, TContext>,
) =>
  useMutation<PageSectionCreateMutationResponse, TError, PageSectionCreateMutationVariables, TContext>(
    ['pageSectionCreate'],
    (
      variables:
        | PageSectionCreateMutationVariables
        | ComputedRef<PageSectionCreateMutationVariables>
        | Ref<PageSectionCreateMutationVariables>,
    ) =>
      appFetcher<PageSectionCreateMutationResponse, PageSectionCreateMutationVariables>(
        PageSectionCreateDocument,
        variables,
      )(),
    options,
  );
usePageSectionCreateMutation.getKey = () => ['pageSectionCreate'];
